@media screen and (max-width:480px) {
    .searchBar {
        width: 190px !important;
    }

    .brandImage {
        width: 48px !important;
        max-height: 48x !important;
        max-width: 48px !important;
    }
}

@media screen and (min-width:480px) {
    .searchBar {
        width: 90% !important;
    }

    .brandImage {
        max-height: 48px !important;
    }
}