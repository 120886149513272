.btnPink {
  padding: 15px 50px !important;
  font-size: 1.2em !important;
  background-color: orange !important;
  color: #ffffff !important;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.btnPink:hover {
  background-color: green !important;
  transform: translateY(-2px) !important;
}

.hero {
  background: white;
}