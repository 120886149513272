.section {
  background-color: #f8f9fa;
  color: #333;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paragraph {
  color: #6c757d;
}