.fadeIn {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1500ms ease-out, transform 1000ms ease-out,
    visibility 1500ms ease-out;
}

.fadeInVisible {
  opacity: 1;
  transform: none;
  visibility: visible;
}