.title {
  text-shadow: 0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff;
}

.description {
  text-shadow: 0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff;
}

.icon {
  filter: drop-shadow(0px 0px 1px black)
}